/** External Module Dependencies **/
import React from "react";
import Loadable from "react-loadable";

function Loading() {
	return <div className="curved-loading" />;
}

const Product = Loadable({
	loader: () => import("./components/product/provider/provider"),
	loading: Loading,
});

const routes = [
	{ path: "/", exact: true, name: "Product", component: Product, title: "CURVED.MONEY" }];

export default routes;
