/* reducers.js */
import * as R from "ramda";

import AppInitialState from "./../../../app-state/app-initial-state";

const Reducer = function(metamask = AppInitialState.metamask, action = {}) {
	switch (action.type) {
		case "LOAD_ETHEREUM_PROVIDER":
			const _metamask = {};

			_metamask.spac_contract = R.pathOr(null, ["spac_contract"], action);
			_metamask.provider = R.pathOr(null, ["provider"], action);
			_metamask.web3 = R.pathOr(null, ["web3"], action);

			return { ...metamask, ..._metamask };

		default:
			return metamask;
	}
};

export default Reducer;
