import React from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";

import { abi_curvedspac } from "./../common/abi-curvedspac";

type MetaMaskPropTypes = {};

type MetaMaskStateTypes = {};

const SPAC_CONTRACT_ADDRESS = "0x69B52343bF553e136E0e967dFC35DA738FfA9eCa";

class MetaMask extends React.PureComponent<any, MetaMaskPropTypes, MetaMaskStateTypes> {
	props: MetaMaskPropTypes;
	state: MetaMaskStateTypes;

	constructor(props) {
		super(props);

		this.state = {
			spac_contract: null,
			provider: null,
			web3: null,
		};
	}

	componentDidMount(): void {
		(async () => {
			const provider = await detectEthereumProvider();

			if (provider) {
				const _state = {};

				_state.provider = provider;
				_state.web3 = new Web3(provider);
				_state.spac_contract = new _state.web3.eth.Contract(abi_curvedspac, SPAC_CONTRACT_ADDRESS);

				this.setState({
					...this.state,
					..._state,
				}, () => {
					const { loadProvider } = this.props;
					const { spac_contract, provider, web3 } = this.state;

					loadProvider(spac_contract, provider, web3);
				});
			} else {
				console.log("Looks like you need a dApp browser to get started.");
				console.log("Consider installing MetaMask!");
			}
		})();
	}

	render(): void {
		return <React.Fragment />;
	}

	// refreshSignedValues = () => {
	// 	if (typeof window.ethereum === "undefined") {
	// 		console.log("Looks like you need a dApp browser to get started.");
	// 		console.log("Consider installing MetaMask!");
	// 	} else {
	// 		ethereum
	// 			.enable()
	// 			.then((accounts) => {
	// 				if (ethereum.networkVersion !== "1") {
	// 					console.log("Accessing testnet network.");
	//
	// 					// We plan to provide an API to make this request in the near future.
	// 					// https://github.com/MetaMask/metamask-extension/issues/3663
	// 				}
	//
	// 				// Once you have a reference to user accounts, you can suggest transactions and signatures:
	// 				const account = accounts[0];
	//
	// 				web3.eth.getBalance(account, web3.eth.defaultBlock, (error, balance) => {
	// 					const _state = {};
	//
	// 					if (balance) {
	// 						_state.account = account;
	// 						_state.balance = (balance.toString(10) / 1e18).toFixed(6);
	// 						_state.order_amount = _state.balance * 0.01;
	//
	// 						this.setState({
	// 							...this.state,
	// 							..._state,
	// 						});
	// 					}
	// 				});
	// 			})
	// 			.catch((reason) => {
	// 				if (reason === "User rejected provider access") {
	// 					console.error("User rejected provider access.");
	// 				} else {
	// 					console.error(reason);
	// 				}
	// 			});
	// 	}
	// };
}

MetaMask.propTypes = {};

export default MetaMask;
