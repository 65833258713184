/* App.js */

/** External Module Dependencies **/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

/** Global Module Dependencies **/
import "bootstrap/dist/css/bootstrap-reboot.css";
import "bootstrap/dist/css/bootstrap.css";
import "shards-ui/dist/css/shards.css";
import "animate.css/animate.min.css";
import "./App.css";

import routes from "./routes";
import Curves from "./components/curves/elements/curves";
import MetaMask from "./components/metamask/provider/provider";

class App extends Component {
	render() {
		return (
			<div className="App">
				<div className="main-content">
					<Curves />
					<Switch>
						{routes.map((route, idx) => {
							return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => <route.component {...props} />} /> : null;
						})}
					</Switch>
					<MetaMask />
				</div>
			</div>
		);
	}
}
export default App;
