import React from "react";

import "./../styles/styles.css";

type CurvesPropTypes = {};

type CurvesStateTypes = {};

class Curves extends React.PureComponent<any, CurvesPropTypes, CurvesStateTypes> {
	props: CurvesPropTypes;
	state: CurvesStateTypes;

	constructor(props) {
		super(props);

		this.state = {};
		this.canvas = null;
		this.gl = null;
		this.ut = null;
		this.st = Date.now();
	}

	componentDidMount(): void {
		this.canvas = document.querySelector("canvas");
		this.canvas.width = window.innerWidth;
		this.canvas.height = window.innerHeight;

		this.initGraphics();
		this.renderCanvas();
	}

	initShaders = (gl, vertexShaderId, fragmentShaderId) => {
		let vertexEl = document.querySelector(vertexShaderId);
		let vertexShader = gl.createShader(gl.VERTEX_SHADER);

		gl.shaderSource(vertexShader, vertexEl.text);
		gl.compileShader(vertexShader);

		if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
			debugger;
		}

		const fragmentEl = document.querySelector(fragmentShaderId);
		const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);

		gl.shaderSource(fragmentShader, fragmentEl.text);
		gl.compileShader(fragmentShader);

		if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
			debugger;
		}

		const program = gl.createProgram();

		gl.attachShader(program, vertexShader);
		gl.attachShader(program, fragmentShader);
		gl.linkProgram(program);
		gl.useProgram(program);

		return program;
	};

	initGraphics = () => {
		this.gl = this.canvas.getContext("webgl");

		const width = this.canvas.width;
		const height = this.canvas.height;

		this.gl.viewport(0, 0, width, height);

		const program = this.initShaders(this.gl, "#sv", "#sf");
		const buffer = this.gl.createBuffer();

		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]), this.gl.STATIC_DRAW);

		const vPosition = this.gl.getAttribLocation(program, "vPosition");
		this.gl.vertexAttribPointer(vPosition, 2, this.gl.FLOAT, false, 0, 0);
		this.gl.enableVertexAttribArray(vPosition);

		this.ut = this.gl.getUniformLocation(program, "time");

		const resolution = new Float32Array([this.canvas.width, this.canvas.height]);
		this.gl.uniform2fv(this.gl.getUniformLocation(program, "resolution"), resolution);
	};

	renderCanvas = () => {
		this.gl.uniform1f(this.ut, (Date.now() - this.st) / 1000);
		this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
		requestAnimationFrame(this.renderCanvas);
	};

	render(): void {
		return (
			<React.Fragment>
				<canvas className="curves" />
			</React.Fragment>
		);
	}
}

Curves.propTypes = {};

export default Curves;
