const loadEthereumProvider = (spac_contract, provider, web3) => {
	return {
		type: "LOAD_ETHEREUM_PROVIDER",
		spac_contract,
		provider,
		web3,
	};
};

export const loadProvider = (spac_contract, provider, web3) => {
	return function(dispatch, getState) {
		dispatch(loadEthereumProvider(spac_contract, provider, web3));
	};
};
