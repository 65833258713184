/* provider.js */

/** External Module Dependencies **/
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";

/** Internal Module Dependencies **/
import MetaMask from "./../elements/metamask";
import { loadProvider } from "./../actions/actions";

const mapStateToProps = (state) => {
	const spac_contract = R.pathOr(null, ["metamask", "spac_contract"], state);
	const provider = R.pathOr(null, ["metamask", "provider"], state);
	const web3 = R.pathOr(null, ["metamask", "web3"], state);

	return {
		spac_contract,
		provider,
		web3,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ loadProvider }, dispatch);
};

const Provider = connect(
	mapStateToProps,
	mapDispatchToProps
)(MetaMask);

export default Provider;
