/* reducer.js */

/** External Module Dependencies **/
import { combineReducers } from "redux";

/** Internal Module Dependencies **/
import metamask from "./../components/metamask/reducer/reducer";
import product from "./../components/product/reducer/reducer";

export default combineReducers({
	metamask,
	product,
});
